<!--explain：;@author: cy; 网点分析项目配置 @version: 1.0; date: 2022/4/6;-->
<template>
  <div>
    <k-crud-page>
      <template #condition>
        <!--        <k-text-input-->
        <!--          v-model="cruddataMX.conditions.searchParam"-->
        <!--          class="my-1"-->
        <!--          label="机构信息"-->
        <!--        />-->
        <k-tree-select
          ref="tree"
          v-model="searchVal"
          :items="treeData"
          label="机构信息"
          tree-key="uuid"
        />
        <k-primary-button
          class="my-1 mr-4"
          icon="mdi-magnify"
          text="搜索"
          @click="searchBtn"
        />
      </template>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :total="cruddataMX.pagination.total"
          :value="cruddataMX.pagination.current"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #roleVos="{ item }">
            <k-switch
              :false-value="yesOrNo('NO', dictData.YesNoStatusEnum)"
              :label="
                item.status === yesOrNo('YES', dictData.YesNoStatusEnum)
                  ? '启用'
                  : '停用'
              "
              :readonly="!hasPermission(['analysis.config.add'])"
              :true-value="yesOrNo('YES', dictData.YesNoStatusEnum)"
              :value="item.status"
              @change="changeStatus(item)"
            />
          </template>
          <template #top>
            <v-toolbar dense flat>
              <v-toolbar-title>数据列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                v-if="hasPermission(['analysis.config.add'])"
                icon="mdi-plus"
                text="添加"
                @click="
                  crudActionMX().newItem(crud.default, beforeOpen(false, 3))
                "
              />
              <k-crud-dialog
                v-model="crud.switch"
                :actionshow="actionShow"
                :title="crud.title"
                maxwidth="950px"
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                    polygons = [];
                    img = '';
                  }
                "
                @save="save"
              >
                <template #dialogContent>
                  <!--                  <div>-->
                  <!--                    path1-{{ cruddataMX.data.path1 }} 实时坐标-{{ pointList1 }}-->
                  <!--                    <br />-->
                  <!--                    path2-{{ cruddataMX.data.path2 }}实时坐标-{{ pointList2 }}-->
                  <!--                  </div>-->
                  <v-form :ref="crud.form.ref">
                    <v-row no-gutters>
                      <v-col :cols="6">
                        <k-tree-select
                          v-model="cruddataMX.data.orgUuid"
                          :disabled="disableAll"
                          :items="treeData"
                          :rules="[globalRules.multiple]"
                          label="机构"
                          tree-key="uuid"
                          @change="formTreeChange"
                        />
                      </v-col>
                      <v-col :cols="6">
                        <k-select
                          v-model="cruddataMX.data.psmUuid"
                          :disabled="disableAll"
                          :items="hostData"
                          :rules="[globalRules.empty]"
                          label="主机"
                        ></k-select>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col :cols="6">
                        <k-tree-select
                          v-model="cruddataMX.data.itemsUuid"
                          :disabled="disableAll"
                          :items="analysisData"
                          :open-on-click="true"
                          :rules="[globalRules.multiple]"
                          label="分析项目"
                          tree-key="value"
                          tree-label="label"
                          @change="analysisDataChange"
                        ></k-tree-select>
                      </v-col>
                    </v-row>
                    <div
                      v-if="showTab"
                      class="rounded-lg py-5"
                      style="background-color: #f5f6fa"
                    >
                      <v-row class="mb-3" no-gutters>
                        <v-col :cols="6" class="pl-16">
                          <span
                            class="text-subtitle-1 font-weight-bold text--black"
                          >配置参数</span
                          >
                        </v-col>
                      </v-row>

                      <!--                      <v-row-->
                      <!--                        v-if='["91e3c6657540421ab9beef7aea43c9b1","54b3ffdf863343f5a834529a7636f504"].includes(cruddataMX.data.moduleUuid)'-->
                      <!--                        no-gutters-->
                      <!--                      >-->
                      <!--                        <v-col  :cols='6'>-->
                      <!--                          <k-select-->
                      <!--                            v-model='cruddataMX.data.counterUuid'-->
                      <!--                            :disabled='disableAll'-->
                      <!--                            :items='counterData'-->
                      <!--                            :rules='[globalRules.empty]'-->
                      <!--                            label='柜台'-->
                      <!--                          />-->
                      <!--                        </v-col>-->
                      <!--                        <v-col :cols='6'>-->
                      <!--                          <k-select-->
                      <!--                            v-model='cruddataMX.data.camera1Uuid'-->
                      <!--                            :disabled='disableAll'-->
                      <!--                            :items='cameraData'-->
                      <!--                            :rules='[globalRules.empty]'-->
                      <!--                            label='摄像头'-->
                      <!--                            @change='kabaCameraChange'-->
                      <!--                          />-->
                      <!--                        </v-col>-->
                      <!--                      </v-row>-->
                      <div class="mx-16 mb-7">
                        <v-tabs
                          v-if="showTab"
                          v-model="tabModel"
                          background-color="#F5F6FA"
                          @change="tabChange"
                        >
                          <div
                            style="
                              width: 100%;
                              position: absolute;
                              bottom: 0;
                              background-color: #c2c2c2;
                              height: 2px;
                            "
                          ></div>
                          <v-tab v-for="(item, i) in cameraArr" :key="i">
                            <div
                              :style="{
                                color:
                                  tabModel === i
                                    ? $vuetify.theme.themes.light.primary.base
                                    : '',
                              }"
                              class="tab-title"
                            >
                              {{ item.name }}
                            </div>
                            <v-card-title>{{ item }}</v-card-title>
                          </v-tab>
                        </v-tabs>
                      </div>
                      <v-row class="mx-16">
                        <v-col :cols="4"></v-col>
                      </v-row>
                      <v-row class="mx-16" no-gutters>
                        <v-col :cols="3" :gutters="12">
                          <v-row>
                            <v-col :cols="11">
                              <k-select
                                v-if="isRiskTran && tabModel === 0"
                                v-model="cruddataMX.data['counterUuid1']"
                                :disabled="disableAll"
                                :items="counterData"
                                :rules="[globalRules.empty]"
                                label="柜台"
                              />
                              <k-select
                                v-if="isRiskTran && tabModel === 1"
                                v-model="cruddataMX.data['counterUuid2']"
                                :disabled="disableAll"
                                :items="counterData"
                                label="柜台"
                              />
                              <template v-if="cameraArr.length === 3">
                                <k-select
                                  v-if="isRiskTran && tabModel === 2"
                                  v-model="cruddataMX.data['counterUuid3']"
                                  :disabled="disableAll"
                                  :items="counterData"
                                  label="柜台"
                                />
                              </template>
                            </v-col>
                            <v-col :cols="11">
                              <k-select
                                v-if="tabModel === 0 && showTab"
                                v-model="cruddataMX.data[`camera1Uuid`]"
                                :disabled="disableAll"
                                :items="cameraData"
                                :rules="[globalRules.empty]"
                                @change="cameraChange"
                              />
                              <k-select
                                v-if="tabModel === 1 && showTab"
                                v-model="cruddataMX.data[`camera2Uuid`]"
                                :disabled="disableAll"
                                :items="cameraData"
                                @change="cameraChange"
                              />
                              <template v-if="cameraArr.length === 3">
                                <k-select
                                  v-if="showTab && tabModel === 2"
                                  v-model="cruddataMX.data[`camera3Uuid`]"
                                  :disabled="disableAll"
                                  :items="cameraData"
                                  @change="cameraChange"
                                />
                              </template>
                            </v-col>
                            <v-col :cols="12">
                              <v-btn
                                class="mx-auto"
                                color="secondary"
                                @click="showImg"
                              >显示画面
                              </v-btn>
                            </v-col>
                            <v-col :cols="12">
                              <v-btn
                                v-if="showTab && !disableAll"
                                class="m-auto"
                                color="success"
                                @click="clearPoint1"
                              >清理画面
                              </v-btn>
                            </v-col>
                            <template v-for="item in btnShowText">
                              <v-col
                                v-if="
                                  (!item.showCamera && item.showCamera !== 0) ||
                                  item.showCamera === tabModel
                                "
                                :key="item.code"
                                :cols="12"
                              >
                                <v-btn
                                  :color="item.color"
                                  :disabled="disableAll"
                                  class="m-auto"
                                  style="color: white"
                                  @click="
                                    drawCanvasAction(item.code, item.color)
                                  "
                                >{{ item.text }}
                                </v-btn>
                              </v-col>
                            </template>
                            <v-col :cols="12">
                              <v-checkbox
                                v-show="showVipCheck"
                                v-model="
                                  cruddataMX.data[
                                    `clientDiscern${tabModel + 1}`
                                  ]
                                "
                                :disabled="disableAll"
                                :false-value="
                                  yesOrNo('NO', dictData.YesNoStatusEnum)
                                "
                                :true-value="
                                  yesOrNo('YES', dictData.YesNoStatusEnum)
                                "
                                color="primary"
                                label="重点人员识别"
                              />
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col :cols="9" class="img-content">
                          <div ref="camImg1">
                            <template v-if="img">
                              <v-img
                                :contain="true"
                                :src="img"
                                aspect-ratio="1"
                                class="grey lighten-2"
                                max-height="360px"
                                max-width="746px"
                                :style="{ height: imgInfo.cH + 'px' }"
                              >
                                <!-- @load="monitoringPic1Load" -->
                                <template #placeholder>
                                  <v-row
                                    align="center"
                                    class="fill-height ma-0"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      color="grey lighten-5"
                                      indeterminate
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </template>
                          </div>
                          <!-- <canvas
                            v-if="Object.keys(btnShowText).length > 0 && img"
                            ref="camCanvas1"
                            class="camCanvas1"
                          ></canvas> -->
                          <DrawPolygon
                            v-if="Object.keys(btnShowText).length > 0 && img"
                            ref="camCanvas1"
                            :style="{ height: imgInfo.cH + 'px' }"
                            :camera="tabModel"
                            :disabled="disableAll"
                            :draw-code="drawCode"
                            :show-polygons="polygons"
                            :storke-color="strokeColor"
                            class="camCanvas1"
                            @deletePolygon="deletePolygon"
                            @drawDown="setPolygon"
                            @drawMove="polygonsMove"
                          >
                          </DrawPolygon>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <k-table-primary-btn
              v-if="hasPermission(['analysis.config.add'])"
              text="查看"
              @click="
                crudActionMX().details(
                  crud,
                  item.uuid,
                  beforeOpen(item, 1),
                  formatData
                )
              "
            />
            <k-table-warning-btn
              v-if="hasPermission(['analysis.config.edit'])"
              class="mx-4"
              text="编辑"
              @click="crudActionMX().editItem(item, () => beforeOpen(item, 2))"
            />
            <k-table-error-btn
              v-if="hasPermission(['analysis.config.delete'])"
              text="删除"
              @click="crudActionMX().deleteItem(item)"
            />
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>

<script>
import { draw } from '@/views/business/networkAnalysisConfig/draw';
import { drawLineP } from '@/views/business/networkAnalysisConfig/line';
import DrawPolygon from './polygons.vue';

export default {
  name: 'NetworkAnalysisConfig',
  components: { DrawPolygon },
  data() {
    return {
      drawCode: '', //绘图记录及状态 code:当前绘图项目；status：状态（1：绘图中，2：完成；0:暂停）
      strokeColor: '#1791fc',
      polygons: [],
      itemsPUuidCamera3: ['54b3ffdf863343f5a834529a7636f504'],
      itemsUuid4Camera3: [
        'fd2f272de0004af6afc8368c33eb3012',
        'fd2f272de0004af6afc8368c33eb30aa',
        '3ffc75e3064e45bcaed8b7ca9c1eadd9',
        '1830bcd60a6d48f49f72adc1f5b83cda',
        '1ba144fe90004e41acb37c66fd896710',
      ], //摄像头3uuid
      img: '',
      lineList: [],
      boxWidth: '0', //图片盒子高度
      boxHeight: '0', //图片盒子宽度
      tabData: [],
      tabModel: 0,
      isAdd: false, //是否新增
      disableAll: false, // 全部禁用
      searchVal: [],
      dictData: {}, //字典
      treeData: [], // 机构数据
      hostData: [], //主机列表
      analysisData: [], //分析项目数据
      cameraData: [], //摄像头列表
      counterData: [], //柜台列表
      cameraModel: '',
      isRiskTran: false,
      actionShow: true,
      crud: {
        name: '分析项目',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'networkAnalysisConfigForm',
        },
        default: {
          uuid: null, //编辑ID
          orgUuid: [], //机构UUID[机构Tree接口]
          psmUuid: '', //主机UUID[主机下拉选接口]
          itemsCode: '', //分析项目Code[分析项目下拉选]
          itemsUuid: [], //分析项目UUID[分析项目下拉选]
          moduleUuid: '', //分析项目对应的模块UUID[分析项目下拉选]
          counterUuid: null, //柜台UUID[柜台下拉选]
          counterUuid1: null,
          counterUuid2: null,
          counterUuid3: null,
          camera1Uuid: '', //摄像头1UUID [摄像头下拉选]
          path1: null, //摄像头1的框点区域JSON
          clientDiscern1: null, //摄像头1是否开启重点人员识别 [枚举字典YesNoStatusEnum]
          camera2Uuid: null, //摄像头2UUID [摄像头下拉选]
          camera3Uuid: null, //摄像头3UUID [摄像头下拉选]
          path2: null, //摄像头2的框点区域JSON,
          path3: null, //摄像头3的框点区域JSON,
          clientDiscern2: null, //摄像头2是否开启重点人员识别 [枚举字典YesNoStatusEnum]
          monitoringPic: '', //监控图片前端使用
          status: '',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'indexNum',
          sortable: false,
          class: '',
        },
        {
          text: '机构名称',
          value: 'orgName',
          sortable: false,
        },
        {
          text: '金融数字员工类型',
          value: 'modelName',
          sortable: false,
        },
        {
          text: '分析项目',
          value: 'itemsName',
          sortable: false,
        },
        {
          text: '位置',
          value: 'areaName',
          sortable: false,
        },
        {
          text: '分析服务器',
          value: 'psmIpAddr',
          sortable: false,
        },
        {
          text: '摄像头1',
          value: 'camera1Name',
          sortable: false,
        },
        {
          text: '摄像头2',
          value: 'camera2Name',
          sortable: false,
        },
        {
          text: '摄像头3',
          value: 'camera3Name',
          sortable: false,
        },
        {
          text: '状态',
          value: 'roleVos',
          sortable: false,
        },
        { text: '操作', value: 'actions', sortable: false },
      ],
      imgInfo: {}, //视屏图片实际宽高
      pointList1: [], //坐标集合
      pointList2: [], //坐标集合
    };
  },
  computed: {
    cameraArr() {
      const pUuid = this.cruddataMX.data.moduleUuid;
      const itemsUuid =
        this.cruddataMX.data.itemsUuid && this.cruddataMX.data.itemsUuid[0]
          ? this.cruddataMX.data.itemsUuid[0]
          : '';
      if (this.showVipCheck) {
        return ['摄像头1', '摄像头2'];
      } else if (
        this.itemsUuid4Camera3.includes(itemsUuid) ||
        this.itemsPUuidCamera3.includes(pUuid)
      ) {
        return ['摄像头1', '摄像头2', '摄像头3'];
      } else {
        return ['摄像头1', '摄像头2'];
      }
    },
    showTab() {
      let flag = false;
      const code = this.cruddataMX.data.itemsCode;
      flag = code !== '';
      return flag;
    },
    showLeftBar() {
      const code = this.cruddataMX.data.itemsCode;
      return code && code !== 'kaba';
    },
    btnShowText() {
      let textObj = {};
      const code = this.cruddataMX.data.itemsCode;
      const types = [
        {
          text: '进出区域',
          code: 'in_out_area',
          products: ['room_insp', 'room_atm', 'room_io', 'cashbox_stock'],
        },
        {
          text: '进线',
          code: 'in_line',
          products: [
            'room_atm',
            'room_atmclr',
            'site_flow',
            'room_insp',
            'cashbox_stock',
            'room_io',
          ],
        },
        {
          text: '出线',
          code: 'out_line',
          products: [
            'room_atm',
            'room_atmclr',
            'site_flow',
            'room_insp',
            'cashbox_stock',
            'room_io',
          ],
        },
        {
          text: '现金检测区',
          code: 'cash_area',
          products: [
            'room_atm',
            'room_atmclr',
            'room_insp',
            'room_io',
            'cashbox_stock',
          ],
        },
        {
          text: '密码区',
          code: 'password_area',
          products: ['room_atmclr', 'room_insp', 'room_io', 'cashbox_stock'],
        },
        {
          text: '识别区',
          code: 'area',
          products: [
            'cashbox_stock',
            'cash_adjust',
            'cashbox_exchange',
            'kaba',
            'leave_post',
            'play_phone',
            'foot_up',
            'laptop',
            'eate_takeout',
            'leave_counter',
            'night_lights',
            'display_lit',
            'leave_official_seal',
            'leave_money',
            'leave_light_screen',
            'night_person',
            'water_glass',
            'dress',
            'dialogue',
            'room_io',
            'room_insp',
            'room_atm',
            'cabinet_people',
            'leave_cashbox',
            'sleep_post',
            'fire_security',
            'person_gather',
            'person_stay',
            'security_leave',
            'suspicious_people',
          ],
          //todo需求扯皮
          // showCamera: 0,
        },
        {
          text: '人员识别区',
          code: 'people_area',
          products: ['cash_transport'],
          showCamera: 1,
        },
        {
          text: '客户识别区',
          code: 'customer_area',
          products: ['lin_gui', 'risk_tran'],
          showCamera: 0,
        },
        {
          text: '现金槽',
          code: 'cash_area',
          products: [],
        },
        {
          text: '柜内区域',
          code: 'area',
          products: ['lin_gui', 'risk_tran'],
        },
        {
          text: '晨会识别区',
          code: 'area',
          products: ['monmeet'],
        },
        {
          text: '重点人员识别区',
          code: 'area',
          products: ['key_accounts'],
        },
        {
          text: '识别区1',
          code: 'area1',
          products: ['have_person'],
        },
        {
          text: '识别区2',
          code: 'area2',
          products: ['have_person'],
        },
        {
          text: '识别区3',
          code: 'area3',
          products: ['have_person'],
        },
        {
          text: '识别区4',
          code: 'area4',
          products: ['have_person'],
        },
        {
          text: '客户识别区',
          code: 'customer_area',
          products: ['sign_check'],
        },
        {
          text: '柜内区域',
          code: 'area',
          products: ['sign_check'],
        },
        {
          text: '点钞机数字区域',
          code: 'counter_display_area',
          products: [
            'kaba',
            //           'cashbox_stock',
            'cash_adjust',
            'cashbox_exchange',
          ],
        },
        {
          text: '签字区域',
          code: 'sign_area',
          products: ['sign_check'],
        },
        {
          text: '移动参照区域',
          code: 'area',
          products: ['ipc_move'],
        },
        {
          text: '钞车停放区',
          code: 'car_area',
          products: ['cash_transport'],
        },
      ];
      // const btns = ['warn', 'err', 'primary', 'purple', 'pink'];
      const colors = [
        '#ff9b07',
        '#f5194d',
        '#2A73C5',
        '#9C25B0',
        '#8ac6d1',
        '#ffb6b9',
        '#fae3d9',
        '#bbded6',
      ];
      const current = types.filter((v) => v.products.includes(code));
      colors.forEach((v, i) => {
        if (current[i]) {
          textObj[v] = {
            ...current[i],
            color: v,
          };
        }
      });
      return textObj;
    },
    showVipCheck() {
      const arr3 = ['key_accounts', 'site_flow'];
      const code = this.cruddataMX.data.itemsCode;
      return arr3.includes(code);
    },
  },
  created() {
    this.getTree();
    this.getDict();
    this.getAnalysisData();
    this.crudInitMX(
      this.crud,
      {
        //apis
        list: (params) => this.$api.networkConfig.networkConfig.getPage(params),
        add: (params) => {
          // eslint-disable-next-line no-debugger
          params.orgUuid = params.orgUuid?.toString();
          params.itemsUuid = params.itemsUuid?.toString();
          console.log('add-resetData-before---', params);
          params = this.resetData(params);
          console.log('add-resetData-end---', params);
          let query = this.polygonReq(params);
          if (this.showVipCheck) {
            params[`clientDiscern1`] =
              params[`clientDiscern1`] ||
              this.yesOrNo('NO', this.dictData.YesNoStatusEnum);
            params[`clientDiscern2`] =
              params[`clientDiscern2`] ||
              this.yesOrNo('NO', this.dictData.YesNoStatusEnum);
          }
          for (const queryKey in query) {
            if (query[queryKey] === '') {
              query[queryKey] = null;
            }
          }
          return this.$api.networkConfig.networkConfig.add(query);
        },
        edit: (params) => {
          // eslint-disable-next-line no-debugger
          params.orgUuid = params.orgUuid?.toString();
          params.itemsUuid = params.itemsUuid?.toString();
          console.log('edit-resetData-before---', params);
          params = this.resetData(params);
          console.log('edit-resetData-end---', params);
          let query = this.polygonReq(params);
          console.log(query);
          for (const queryKey in query) {
            if (query[queryKey] === '') {
              query[queryKey] = null;
            }
          }
          return this.$api.networkConfig.networkConfig.update(query);
        },
        delete: this.$api.networkConfig.networkConfig.del,
        details: this.$api.networkConfig.networkConfig.getInfo,
      },
      {
        //defaultConditions
        orgUuid: '',
        orgName: '',
      },
      {
        //subParam
        // add: ['counterUuid1','counterUuid2'],
        edit: [
          'uuid',
          'orgUuid',
          'psmUuid',
          'itemsCode',
          'itemsUuid',
          'moduleUuid',
          'counterUuid',
          'camera1Uuid',
          'path1',
          'clientDiscern1',
          'camera2Uuid',
          'path2',
          'clientDiscern2',
          'counterUuid1',
          'counterUuid2',
          'monitoringPic',
          'status',
        ],
      }
    );
  },
  methods: {
    deletePolygon({ polygon }) {
      const { camera, drawCode } = polygon;
      this.polygons.forEach((v, i) => {
        if (v.camera === camera && v.drawCode === drawCode) {
          this.polygons.splice(i, 1);
        }
      });
    },
    getImageInfo(data) {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = data || this.img;
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
      });
    },
    polygonReq(params) {
      for (let i = 1; i < 4; i++) {
        if (params[`camera${i}Uuid`]) {
          const list = this.polygons.filter((v) => v.camera === i - 1);
          const path1 = {};
          list.forEach((v) => {
            path1[v.drawCode] = {
              code: v.drawCode,
              lineColor: v.storkeColor,
              path: v.circles.map((v) => v.x + ' ' + v.y).join(' '),
              formatPath: v.circles
                .map(
                  (v) =>
                    (v.x / this.imgInfo.cW).toFixed(2) +
                    ' ' +
                    (v.y / this.imgInfo.cH).toFixed(2)
                )
                .join(' '),
            };
          });
          params[`path${i}`] = JSON.stringify(path1);
        }
      }
      return params;
    },
    polygonRes(item) {
      for (let i = 1; i < 4; i++) {
        if (item['path' + i]) {
          const path1 = JSON.parse(item['path' + i]);

          let list = [];
          for (let key in path1) {
            // const rex = new RegExp('.'/g)
            if (path1[key].path) {
              let pathList = path1[key].path.split(' ');
              const arr = [];
              let count = 0;
              pathList.forEach((v, i) => {
                if ((i + 1) % 2 === 0) {
                  arr[count] = arr[count] + ',' + v;
                  count++;
                } else {
                  arr[count] = v;
                }
              });
              console.log(arr);
              // pathList = pathList.join('|')
              const obj = {
                path: arr.join(' '),
                storkeColor: path1[key].lineColor,
                camera: i - 1,
                drawCode: key,
                circles: arr.map((v) => {
                  const d = v.split(',');
                  return {
                    x: +d[0],
                    y: +d[1],
                  };
                }),
              };
              list.push(obj);
            }
          }

          // this.$set(this, 'polygons', this.polygons.concat(list))
          this.polygons = this.polygons.concat(list);
          console.log(list, this.polygons);
        }
      }
      // console.log(this.polygons);
      // this.$refs.camCanvas1.$forceUpdate()
      this.$forceUpdate();
    },
    setPolygon(e) {
      this.polygons = this.polygons.concat(e);
    },
    polygonsMove(e) {
      const notCurrent = this.polygons.filter(
        (value) => value.camera !== this.tabModel
      );
      const list = [...notCurrent, ...e];
      this.$set(this, 'polygons', list);
    },
    save() {
      this.crudActionMX().saveItem();
      console.log(this.cruddataMX.data);
    },
    /*清空编辑的画布坐标*/
    clearPoint() {
      // this.polygons = [];
    },
    clearPoint1() {
      this.polygons = [];
      this.$refs.camCanvas1.polygons = [];
    },
    showImg() {
      const id = this.cruddataMX.data[`camera${this.tabModel + 1}Uuid`];
      if (id) {
        this.cameraImg(id);
      } else {
        this.img = '';
      }
    },
    drawCodeEditor(code) {
      this.drawCode = code;
      const thisCamera = this.polygons.filter(
        (v) => v.camera === this.tabModel
      );
      console.log(thisCamera);
      if (!thisCamera.find((v) => v.drawCode === code)) {
        this.$refs.camCanvas1.edit = true;
      } else {
        this.$refs.camCanvas1.edit = false;
      }
    },
    drawCanvasAction(code, color) {
      this.strokeColor = color;
      this.drawCodeEditor(code);
    },
    monitoringPic1Load() {
      //图片加载完成
      let camImg1 = this.$refs.camImg1;
      this.boxHeight = window.getComputedStyle(camImg1).height;
      this.boxWidth = window.getComputedStyle(camImg1).width;
      console.log('monitoringPic1Load---', this.cruddataMX.data);

      if (this.isAdd) {
        //新增分析处理逻辑
        if (this.showTab) {
          if (this[`pointList${this.tabModel + 1}`].length) {
            if (this.showVipCheck) {
              this.initCanvas(
                this.boxWidth,
                this.boxHeight,
                'red',
                undefined,
                true
              );
            } else {
              this.initCanvas(
                this.boxWidth,
                this.boxHeight,
                'red',
                undefined,
                false
              );
            }
          }
        } else if (Object.keys(this.btnShowText).length === 1) {
          this.initCanvas(
            this.boxWidth,
            this.boxHeight,
            'red',
            undefined,
            false
          );
        }
      }
    },
    initCanvas(w, h, lineColor, index, isLine, code) {
      this.$nextTick(() => {
        // eslint-disable-next-line no-debugger
        // 初始化canvas宽高
        let cav = this.$refs[`camCanvas1`];
        cav.width = w.replace(/px/, '');
        cav.height = h.replace(/px/, '');
        let ctx = cav.getContext('2d');
        ctx.clearRect(0, 0, cav.width, cav.height);
        cav.style.cursor = 'crosshair';
        // 计算使用变量
        let list =
          this[
            `pointList${
              Object.keys(this.btnShowText).length === 1 ? 1 : this.tabModel + 1
            }`
            ]; // 画框数据集合, 用于服务端返回的数据显示和绘制的矩形保存
        if (list.length !== 0 && !isLine) {
          list.forEach((value) => {
            // 遍历绘制所有标记框
            if (value) {
              ctx.beginPath();
              ctx.strokeStyle = value.lineColors;
              ctx.rect(value.x, value.y, value.w, value.h);
              ctx.stroke();
            }
          });
        }
        // 绘制线条
        if (list.length !== 0 && isLine) {
          list.forEach((value) => {
            // 遍历绘制所有标记框
            if (value) {
              ctx.beginPath();
              ctx.lineWidth = 2;
              ctx.strokeStyle = value.lineColors;
              ctx.moveTo(value.x, value.y);
              ctx.lineTo(value.ox, value.oy);
              ctx.stroke();
            }
          });
        }

        const maxNum = Object.keys(this.btnShowText).length === 1 ? 20 : 2;
        // 调用封装的绘制方法
        if (isLine) {
          drawLineP(cav, list, 'green', maxNum, code);
        } else {
          draw(cav, list, index, lineColor, maxNum, code);
        }
      });
    },
    async beforeOpen(item, flag) {
      /*flag 1 查看 2 编辑 3新增*/
      await this.getAnalysisData();
      this.actionShow = flag !== 1;
      this.isAdd = flag === 3;
      this.disableAll = flag === 1;
      this.polygons = [];
      if (flag === 3) {
        this.cruddataMX.data.orgUuid = [this.treeData[0].uuid];
        this.formTreeChange([this.treeData[0].uuid]);
      } else if (flag === 2) {
        const { data, code } =
          await this.$api.networkConfig.networkConfig.getInfo(item);
        if (code === 200) {
          this.$set(this.cruddataMX, 'data', this.formatData(data[0]));
          this.analysisDataChange(this.cruddataMX.data.itemsUuid);
        }
      } else if (flag === 1) {
        // this.formTreeChange(this.cruddataMX.data.orgUuid);
      }
    },
    formatData(resData) {
      if (resData) {
        resData.orgUuid = resData.orgUuid ? [resData.orgUuid] : [];
        resData.itemsUuid = resData.itemsUuid ? [resData.itemsUuid] : [];
      }
      this.polygonRes(resData);
      this.formTreeChange(resData.orgUuid);
      setTimeout(() => {
        console.log(this.polygons);
        this.$refs.camCanvas1.initPolygons();
      }, 50);
      return resData;
    },
    changeStatus(item) {
      const query = {
        uuid: item.uuid,
        status: item.status === '01' ? '02' : '01',
      };
      this.$api.networkConfig.networkConfig
        .changeStatus(query)
        .then(({ code, message }) => {
          if (code === 200) {
            this.$alert.success(message);
            this.crudActionMX().list();
          }
        });
    },
    //保存入参格式化默认值
    resetData(queryData) {
      // eslint-disable-next-line no-debugger
      // const imgBoxW = this.boxWidth.replace(/px/, '');
      // const imgBoxH = this.boxHeight.replace(/px/, '');
      // const pointList1 = JSON.parse(JSON.stringify(this.pointList1));
      // const pointList2 = JSON.parse(JSON.stringify(this.pointList2));
      // if (this.pointList1.length) {
      //   pointList1.forEach((item) => {
      //     if (item && item.w) {
      //       //计算图片比列 正方形
      //       item.x = (item.x / imgBoxW).toFixed(2);
      //       item.y = (item.y / imgBoxH).toFixed(2);
      //       item.w = (item.w / imgBoxW).toFixed(2);
      //       item.h = (item.h / imgBoxH).toFixed(2);
      //     } else if (item && item.ox) {
      //       //计算图片比列 正方形
      //       item.x = (item.x / imgBoxW).toFixed(2);
      //       item.y = (item.y / imgBoxH).toFixed(2);
      //       item.ox = (item.ox / imgBoxW).toFixed(2);
      //       item.oy = (item.oy / imgBoxH).toFixed(2);
      //     }
      //   });
      // }
      // if (this.pointList2.length) {
      //   pointList2.forEach((item) => {
      //     if (item && item.w) {
      //       //计算图片比列 正方形
      //       item.x = (item.x / imgBoxW).toFixed(2);
      //       item.y = (item.y / imgBoxH).toFixed(2);
      //       item.w = (item.w / imgBoxW).toFixed(2);
      //       item.h = (item.h / imgBoxH).toFixed(2);
      //     } else if (item && item.ox) {
      //       //计算图片比列 正方形
      //       item.x = (item.x / imgBoxW).toFixed(2);
      //       item.y = (item.y / imgBoxH).toFixed(2);
      //       item.ox = (item.ox / imgBoxW).toFixed(2);
      //       item.oy = (item.oy / imgBoxH).toFixed(2);
      //     }
      //   });
      // }
      // if (this.showTab) {
      //   queryData.counterUuid = null;
      // } else {
      //   queryData.camera2Uuid = null;
      //   queryData.clientDiscern2 = null;
      // }
      // const codeWarn = this.btnShowText?.warn?.code;
      // const codeErr = this.btnShowText?.err?.code;
      // if (codeWarn || codeErr) {
      //   queryData.path1 = {};
      //   queryData.path2 = {};
      //   if (codeWarn) {
      //     queryData.path1[codeWarn] =
      //       pointList1.filter((item) => item?.code === codeWarn) || null;
      //     if (this.pointList2.length) {
      //       queryData.path2[codeWarn] =
      //         pointList2.filter((item) => item?.code === codeWarn) || null;
      //     } else {
      //       queryData.path2 = null;
      //     }
      //     // queryData.path1 = {
      //     //   [codeErr]: this.pointList1.filter((item) => item?.code === codeErr),
      //     // };
      //     // queryData.path2 = {
      //     //   [codeWarn]: this.pointList2.filter((item) => item?.code === codeWarn),
      //     //   [codeErr]: this.pointList2.filter((item) => item?.code === codeErr),
      //     // };
      //   }
      //   if (codeErr) {
      //     queryData.path1[codeErr] =
      //       pointList1.filter((item) => item?.code === codeErr) || null;
      //     if (this.pointList2.length) {
      //       // queryData.path2 = {};
      //       queryData.path2[codeErr] =
      //         pointList2.filter((item) => item?.code === codeErr) || null;
      //     }
      //   }
      //   if (queryData.path1) {
      //     queryData.path1 = JSON.stringify(queryData.path1);
      //   }
      //   if (queryData.path2) {
      //     queryData.path2 = JSON.stringify(queryData.path2);
      //   }
      // } else {
      //   queryData.path1 = null;
      //   queryData.path2 = null;
      // }
      // if (this.showVipCheck) {
      //   queryData.clientDiscern1 = queryData.clientDiscern1 || '02';
      //   queryData.clientDiscern2 = queryData.clientDiscern2 || '02';
      // } else {
      //   queryData.clientDiscern1 = null;
      //   queryData.clientDiscern2 = null;
      // }
      // eslint-disable-next-line no-debugger
      return queryData;
    },
    yesOrNo(flag, dict) {
      if (dict && dict.length) {
        return dict.find((item) => item.name === flag).code;
      } else {
        // console.error('dict type Array');
      }
    },
    tabChange(e) {
      let index = e + 1;
      const id = this.cruddataMX.data[`camera${index}Uuid`];
      if (id) {
        this.cameraImg(id);
      } else {
        this.cruddataMX.data[`camera${index}Uuid`] = null;
        this.img = '';
      }
      if (Object.keys(this.btnShowText).length && this.img) {
        let flag = this.btnShowText.warn.code === 'in_line';
        this.initCanvas(this.boxWidth, this.boxHeight, '', undefined, flag);
      }
    },
    //搜索
    searchBtn() {
      // if (this.$refs.tree.treeName) {
      this.cruddataMX.conditions.orgName = this.$refs.tree.treeName;
      // }
      this.crudConditionsMX().resetPage();
    },
    treeChange(e) {
      const self = this;
      this.cruddataMX.conditions.orgUuid = e?.toString();
      if (e.length) {
        searchName(this.treeData);
      } else {
        self.cruddataMX.conditions.orgName = '';
      }

      function searchName(data) {
        if (data.length) {
          data.map((item) => {
            if (item.uuid === self.cruddataMX.conditions.orgUuid) {
              self.cruddataMX.conditions.orgName = item.name;
            } else if (item?.children?.length) {
              searchName(item.children);
            }
          });
        }
      }
    },
    //卡把摄像头切换
    kabaCameraChange(v) {
      if (v) {
        this.cameraImg(v);
      }
    },
    //摄像头1联动请求画面
    cameraChange(v) {
      if (v) {
        this.img = '';
        this.cameraImg(v);
      }
    },
    cameraImg(cameraUuid) {
      if (!cameraUuid) {
        this.$alert.warning('请先选择一个摄像头');
        return;
      }
      const that = this;
      this.$api.networkConfig.networkConfig
        .getCameraImg({ cameraUuid })
        .then(({ data, code }) => {
          if (code === 200) {
            // eslint-disable-next-line no-debugger
            this.$nextTick(async () => {
              // this.img = data[0];
              that.$set(that, 'img', data[0]);
              this.imgInfo = await this.getImageInfo(data[0]);
              this.imgInfo.p =
                this.$refs.camImg1.clientWidth / this.imgInfo.width;
              this.imgInfo.cH = this.imgInfo.height * this.imgInfo.p;
              this.imgInfo.cW = this.$refs.camImg1.clientWidth;
              // that.$set(that.cruddataMX.data, 'monitoringPic', data[0]);
              // that.cruddataMX.data.monitoringPic = data[0];
              // that.$forceUpdate();
            });
          }
        });
    },
    //分析项目处理逻辑
    analysisDataChange(val) {
      const that = this;

      function searchCode(data) {
        data.map((item) => {
          if (item.value === val[0]) {
            that.cruddataMX.data.itemsCode = item.code;
            that.cruddataMX.data.moduleUuid = item.puuid;
            console.log(item);
            that.isRiskTran =
              (['dialogue', 'risk_tran', 'kaba', 'lin_gui'].includes(
                  item.code
                ) ||
                item.puuid === '54b3ffdf863343f5a834529a7636f504') &&
              !['security_leave'].includes(item.code);
          } else if (item.children?.length) {
            searchCode(item.children);
          }
        });
      }

      if (val?.length) {
        searchCode(this.analysisData);
      } else {
        this.cruddataMX.data.itemsCode = '';
        // this.cruddataMX.data = {
        //   orgUuid: this.cruddataMX.data.orgUuid, //机构UUID[机构Tree接口]
        //   psmUuid: this.cruddataMX.data.psmUuid, //主机UUID[主机下拉选接口]
        //   itemsCode: '', //分析项目Code[分析项目下拉选]
        //   itemsUuid: [], //分析项目UUID[分析项目下拉选]
        //   moduleUuid: '', //分析项目对应的模块UUID[分析项目下拉选]
        //   counterUuid: null, //柜台UUID[柜台下拉选]
        //   camera1Uuid: '', //摄像头1UUID [摄像头下拉选]
        //   path1: '{}', //摄像头1的框点区域JSON
        //   clientDiscern1: null, //摄像头1是否开启重点人员识别 [枚举字典YesNoStatusEnum]
        //   camera2Uuid: null, //摄像头2UUID [摄像头下拉选]
        //   path2: '{}', //摄像头2的框点区域JSON
        //   clientDiscern2: null, //摄像头2是否开启重点人员识别 [枚举字典YesNoStatusEnum]
        //   monitoringPic: '', //监控图片前端使用
        // };
      }
      this.clearPoint();
      console.log('itemsCode----', this.cruddataMX.data.itemsCode);
    },
    //机构树联动请求其他数据接口
    formTreeChange(e) {
      if (e.length) {
        this.getCounterData(e.toString());
        this.getHostData(e.toString());
        this.getCameraData(e.toString());
      } else {
        this.hostData = [];
        this.cruddataMX.data.psmUuid = '';
        this.counterData = [];
        this.cruddataMX.data.counterUuid = '';
        this.cameraData = [];
      }
      this.clearPoint();
    },
    getCounterData(orgUuid) {
      this.$api.networkConfig.networkConfig
        .getOrgcounter({ orgUuid })
        .then(({ data, code }) => {
          if (code === 200) {
            this.counterData = data;
          }
        });
    },
    getCameraData(orgUuid) {
      this.$api.networkConfig.networkConfig
        .getOrgcamera({ orgUuid })
        .then(({ data, code }) => {
          if (code === 200) {
            this.cameraData = data;
          }
        });
    },
    getHostData(orgUuid) {
      this.$api.networkConfig.networkConfig
        .getOrgPsm({ orgUuid })
        .then(({ data, code }) => {
          if (code === 200) {
            this.hostData = data;
            if (data?.length && this.isAdd) {
              this.cruddataMX.data.psmUuid = data[0].value;
            }
          }
        });
    },
    async getAnalysisData() {
      await this.$api.networkConfig.networkConfig
        .getIntems()
        .then(({ data, code }) => {
          if (code === 200) {
            this.analysisData = data;
          }
        });
    },
    getDict() {
      this.$api.networkConfig.networkConfig.getDict().then(({ data, code }) => {
        if (code === 200) {
          this.dictData = data[0];
        }
      });
    },
    getTree() {
      this.$api.organization.organization.getOrTree().then(({ code, data }) => {
        if (code === 200) {
          this.treeData = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-title {
  font-size: 18px;
  font-weight: 400;
  //color: #606266;
}

.img-content {
  position: relative;

  .camCanvas1 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    background-color: transparent !important;
  }
}
</style>
